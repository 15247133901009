<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'NodeprojectStores',
    components: {  },
    directives: {  },
    data() {
        return {
            
        };
    },
    computed:{
        
    },
    mounted() {
        
    },
    methods: {
        
    },
};
</script>

<style  scoped>
.stores{display:flex;align-items: center;justify-content: center; flex-direction: row;
flex-wrap: wrap;}
.stores img{width: 200px;height: 100px;margin: 10px;}
</style>